import { CardActionArea, CardMedia, Typography, Pagination, AppBar, Toolbar, IconButton} from '@mui/material';
import * as React from "react"


const confirmationPage = () => {
    return(
        <main>
            <Typography variant="h3">Your order is confirmed</Typography>
            <Typography>You will receive an e-mail shortly with your shipping information and a receipt</Typography>
            <Typography>Thank you for shopping with Braxton's Natural Dog Food</Typography>
        </main>
    )

}

export default confirmationPage;